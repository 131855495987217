@charset "UTF-8";
/*Colors - BEGIN*/
/**** Colors - end *****/
:root {
  --echt-transition: cubic-bezier(0.25, 0.1, 0.25, 1); }

/*Colors - BEGIN*/
/**** Colors - end *****/
:root {
  --echt-transition: cubic-bezier(0.25, 0.1, 0.25, 1); }

.o2-color--indigo, .o2-text--indigo {
  color: #000066 !important; }

.o2-color--blue, .o2-color--highlight, .o2-text--blue, .blue {
  color: #005177 !important; }

.o2-color--black, .o2-color--heading, .o2-text--black {
  color: #343434 !important; }

.o2-color--gray, .o2-color--text, .o2-text--gray, .darkgray {
  color: #3b4653 !important; }

.o2-color--brick, .o2-color--sticker, .o2-text--brick {
  color: #990000 !important; }

.o2-color--cyan, .o2-text--cyan {
  color: #00b9b5 !important; }

.o2-color--greenblue, .o2-color--link, .o2-text--greenblue {
  color: #0093d5 !important; }

.o2-color--lightblue, .o2-color--info, .o2-text--lightblue, .o2-text--info {
  color: #0093d5 !important; }

.o2-color--green, .o2-color--success, .o2-text--green, .o2-text--success, .green {
  color: #3bb237 !important; }

.o2-color--orange, .o2-color--warning, .o2-text--orange, .o2-text--warning {
  color: #ff8b2f !important; }

.o2-color--red, .o2-color--danger, .o2-text--red, .o2-text--danger, .red {
  color: #e82b3b !important; }

.o2-color--white, .white {
  color: white !important; }

.o2-color--black, .black {
  color: black !important; }

.o2-color--default {
  color: #cccccc !important; }

.o2-color--gold {
  color: gold !important; }

.o2-bg--indigo, .color-box .blue1 {
  background-color: #000066 !important; }

.o2-bg--blue, .o2-bg--highlight, .color-box .blue2 {
  background-color: #005177 !important; }

.o2-bg--black, .o2-bg--heading, .color-box .black {
  background-color: #343434 !important; }

.o2-bg--gray, .o2-bg--text, .color-box .gray {
  background-color: #3b4653 !important; }

.o2-bg--default {
  background-color: #cccccc !important; }

.o2-bg--brick, .o2-bg--sticker, .color-box .red {
  background-color: #990000 !important; }

.o2-bg--cyan, .color-box .green {
  background-color: #00b9b5 !important; }

.o2-bg--greenblue, .o2-bg--link, .o2-bg--waiting, .color-box .blue3 {
  background-color: #0093d5 !important; }

.o2-bg--lightblue, .o2-bg--info, .color-box .info {
  background-color: #0093d5 !important; }

.o2-bg--green, .o2-bg--success, .color-box .success {
  background-color: #3bb237 !important; }

.o2-bg--orange, .o2-bg--warning, .color-box .warning {
  background-color: #ff8b2f !important; }

.o2-bg--red, .o2-bg--danger, .color-box .danger {
  background-color: #e82b3b !important; }

.o2-bg--gray {
  background-color: #f7f7f9 !important; }

.o2-bg--gold {
  background-color: gold !important; }

/* border */
.o2-border--indigo {
  border-color: #000066 !important; }

.o2-border--blue, .o2-border--highlight {
  border-color: #005177 !important; }

.o2-border--black, .o2-border--heading {
  border-color: #343434 !important; }

.o2-border--gray, .o2-border--text {
  border-color: #3b4653 !important; }

.o2-border--brick, .o2-border--sticker {
  border-color: #990000 !important; }

.o2-border--cyan {
  border-color: #00b9b5 !important; }

.o2-border--greenblue, .o2-border--link {
  border-color: #0093d5 !important; }

.o2-border--lightblue, .o2-border--info {
  border-color: #0093d5 !important; }

.o2-border--green, .o2-border--success {
  border-color: #3bb237 !important; }

.o2-border--orange, .o2-border--warning {
  border-color: #ff8b2f !important; }

.o2-border--red, .o2-border--danger {
  border-color: #e82b3b !important; }

.o2-border--gray {
  border-color: #f7f7f9 !important; }

.o2-border--white {
  border-color: white !important; }

.o2-border--gold {
  border-color: gold !important; }

/*Colors - BEGIN*/
/**** Colors - end *****/
:root {
  --echt-transition: cubic-bezier(0.25, 0.1, 0.25, 1); }

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"), url("../fonts/open-sans/open-sans-v16-latin_latin-ext-300.woff2") format("woff2"), url("../fonts/open-sans/open-sans-v16-latin_latin-ext-300.woff") format("woff"), url("../fonts/open-sans/open-sans-v16-latin_latin-ext-300.ttf") format("truetype"), url("../fonts/open-sans/open-sans-v16-latin_latin-ext-300.svg#OpenSans") format("svg"); }

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans-Regular"), url("../fonts/open-sans/open-sans-v16-latin_latin-ext-regular.woff2") format("woff2"), url("../fonts/open-sans/open-sans-v16-latin_latin-ext-regular.woff") format("woff"), url("../fonts/open-sans/open-sans-v16-latin_latin-ext-regular.ttf") format("truetype"), url("../fonts/open-sans/open-sans-v16-latin_latin-ext-regular.svg#OpenSans") format("svg"); }

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url("../fonts/open-sans/open-sans-v16-latin_latin-ext-600.woff2") format("woff2"), url("../fonts/open-sans/open-sans-v16-latin_latin-ext-600.woff") format("woff"), url("../fonts/open-sans/open-sans-v16-latin_latin-ext-600.ttf") format("truetype"), url("../fonts/open-sans/open-sans-v16-latin_latin-ext-600.svg#OpenSans") format("svg"); }

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("../fonts/open-sans/open-sans-v16-latin_latin-ext-700.woff2") format("woff2"), url("../fonts/open-sans/open-sans-v16-latin_latin-ext-700.woff") format("woff"), url("../fonts/open-sans/open-sans-v16-latin_latin-ext-700.ttf") format("truetype"), url("../fonts/open-sans/open-sans-v16-latin_latin-ext-700.svg#OpenSans") format("svg"); }

/* .font-size classes */
.font-0, .font-lg {
  font-size: 40px; }

.font-1 {
  font-size: 33px; }

.font-2 {
  font-size: 25px; }

.font-3 {
  font-size: 21px; }

.font-4 {
  font-size: 18px; }

.font-5 {
  font-size: 15px; }

.font-6 {
  font-size: 14px; }

.font-zero {
  font-size: 0px; }

.o2f-bold {
  font-weight: bold; }

/*Colors - BEGIN*/
/**** Colors - end *****/
:root {
  --echt-transition: cubic-bezier(0.25, 0.1, 0.25, 1); }

/* margin */
.m-0, .margin-reset {
  margin: 0px !important;
  /* 0px */ }

.m-1 {
  margin: 3px !important;
  /* base * .25 */ }
  @media screen and (min-width: 1200px) {
    .m-1 {
      margin: 6px !important; } }

.m-2 {
  margin: 6px !important;
  /* base * .5 */ }
  @media screen and (min-width: 1200px) {
    .m-2 {
      margin: 12px !important; } }

.m-3 {
  margin: 12px !important;
  /* base */ }
  @media screen and (min-width: 1200px) {
    .m-3 {
      margin: 24px !important; } }

.m-4 {
  margin: 18px !important;
  /* base * 1.5 */ }
  @media screen and (min-width: 1200px) {
    .m-4 {
      margin: 36px !important; } }

.m-5 {
  margin: 36px !important;
  /* base * 3 */ }
  @media screen and (min-width: 1200px) {
    .m-5 {
      margin: 72px !important; } }

/* margin top */
.mt-0 {
  margin-top: 0px !important;
  /* 0px */ }

.mt-1 {
  margin-top: 3px !important;
  /* base * .25 */ }
  @media screen and (min-width: 1200px) {
    .mt-1 {
      margin-top: 6px !important; } }

.mt-2 {
  margin-top: 6px !important;
  /* base * .5 */ }
  @media screen and (min-width: 1200px) {
    .mt-2 {
      margin-top: 12px !important; } }

.mt-3 {
  margin-top: 12px !important;
  /* base */ }
  @media screen and (min-width: 1200px) {
    .mt-3 {
      margin-top: 24px !important; } }

.mt-4 {
  margin-top: 18px !important;
  /* base * 1.5 */ }
  @media screen and (min-width: 1200px) {
    .mt-4 {
      margin-top: 36px !important; } }

.mt-5 {
  margin-top: 36px !important;
  /* base * 3 */ }
  @media screen and (min-width: 1200px) {
    .mt-5 {
      margin-top: 72px !important; } }

/* margin right */
.mr-0 {
  margin-right: 0px !important;
  /* 0px */ }

.mr-1 {
  margin-right: 3px !important;
  /* base * .25 */ }
  @media screen and (min-width: 1200px) {
    .mr-1 {
      margin-right: 6px !important; } }

.mr-2 {
  margin-right: 6px !important;
  /* base * .5 */ }
  @media screen and (min-width: 1200px) {
    .mr-2 {
      margin-right: 12px !important; } }

.mr-3 {
  margin-right: 12px !important;
  /* base */ }
  @media screen and (min-width: 1200px) {
    .mr-3 {
      margin-right: 24px !important; } }

.mr-4 {
  margin-right: 18px !important;
  /* base * 1.5 */ }
  @media screen and (min-width: 1200px) {
    .mr-4 {
      margin-right: 36px !important; } }

.mr-5 {
  margin-right: 36px !important;
  /* base * 3 */ }
  @media screen and (min-width: 1200px) {
    .mr-5 {
      margin-right: 72px !important; } }

/* margin bottom */
.mb-0 {
  margin-bottom: 0px !important;
  /* 0px */ }

.mb-1 {
  margin-bottom: 3px !important;
  /* base * .25 */ }
  @media screen and (min-width: 1200px) {
    .mb-1 {
      margin-bottom: 6px !important; } }

.mb-2 {
  margin-bottom: 6px !important;
  /* base * .5 */ }
  @media screen and (min-width: 1200px) {
    .mb-2 {
      margin-bottom: 12px !important; } }

.mb-3 {
  margin-bottom: 12px !important;
  /* base */ }
  @media screen and (min-width: 1200px) {
    .mb-3 {
      margin-bottom: 24px !important; } }

.mb-4 {
  margin-bottom: 18px !important;
  /* base * 1.5 */ }
  @media screen and (min-width: 1200px) {
    .mb-4 {
      margin-bottom: 36px !important; } }

.mb-5 {
  margin-bottom: 36px !important;
  /* base * 3 */ }
  @media screen and (min-width: 1200px) {
    .mb-5 {
      margin-bottom: 72px !important; } }

/* margin left */
.ml-0 {
  margin-left: 0px !important;
  /* 0px */ }

.ml-1 {
  margin-left: 3px !important;
  /* base * .25 */ }
  @media screen and (min-width: 1200px) {
    .ml-1 {
      margin-left: 6px !important; } }

.ml-2 {
  margin-left: 6px !important;
  /* base * .5 */ }
  @media screen and (min-width: 1200px) {
    .ml-2 {
      margin-left: 12px !important; } }

.ml-3 {
  margin-left: 12px !important;
  /* base */ }
  @media screen and (min-width: 1200px) {
    .ml-3 {
      margin-left: 24px !important; } }

.ml-4 {
  margin-left: 18px !important;
  /* base * 1.5 */ }
  @media screen and (min-width: 1200px) {
    .ml-4 {
      margin-left: 36px !important; } }

.ml-5 {
  margin-left: 36px !important;
  /* base * 3 */ }
  @media screen and (min-width: 1200px) {
    .ml-5 {
      margin-left: 72px !important; } }

/* margin left right */
.mx-0 {
  margin-left: 0px !important;
  /* 0px */
  margin-right: 0px !important;
  /* 0px */ }

.mx-1 {
  margin-left: 3px !important;
  /* base * .25 */
  margin-right: 3px !important;
  /* base * .25 */ }
  @media screen and (min-width: 1200px) {
    .mx-1 {
      margin-left: 6px !important;
      margin-right: 6px !important; } }

.mx-2 {
  margin-left: 6px !important;
  /* base * .5 */
  margin-right: 6px !important;
  /* base * .5 */ }
  @media screen and (min-width: 1200px) {
    .mx-2 {
      margin-left: 12px !important;
      margin-right: 12px !important; } }

.mx-3 {
  margin-left: 12px !important;
  /* base */
  margin-right: 12px !important;
  /* base */ }
  @media screen and (min-width: 1200px) {
    .mx-3 {
      margin-left: 24px !important;
      margin-right: 24px !important; } }

.mx-4 {
  margin-left: 18px !important;
  /* base * 1.5 */
  margin-right: 18px !important;
  /* base * 1.5 */ }
  @media screen and (min-width: 1200px) {
    .mx-4 {
      margin-left: 36px !important;
      margin-right: 36px !important; } }

.mx-5 {
  margin-left: 36px !important;
  /* base * 3 */
  margin-right: 36px !important;
  /* base * 3 */ }
  @media screen and (min-width: 1200px) {
    .mx-5 {
      margin-left: 72px !important;
      margin-right: 72px !important; } }

/* margin top bottom */
.my-0 {
  margin-top: 0px !important;
  /* 0px */
  margin-bottom: 0px !important;
  /* 0px */ }

.my-1 {
  margin-top: 3px !important;
  /* base * .25 */
  margin-bottom: 3px !important;
  /* base * .25 */ }
  @media screen and (min-width: 1200px) {
    .my-1 {
      margin-top: 6px !important;
      margin-bottom: 6px !important; } }

.my-2 {
  margin-top: 6px !important;
  /* base * .5 */
  margin-bottom: 6px !important;
  /* base * .5 */ }
  @media screen and (min-width: 1200px) {
    .my-2 {
      margin-top: 12px !important;
      margin-bottom: 12px !important; } }

.my-3 {
  margin-top: 12px !important;
  /* base */
  margin-bottom: 12px !important;
  /* base */ }
  @media screen and (min-width: 1200px) {
    .my-3 {
      margin-top: 24px !important;
      margin-bottom: 24px !important; } }

.my-4 {
  margin-top: 18px !important;
  /* base * 1.5 */
  margin-bottom: 18px !important;
  /* base * 1.5 */ }
  @media screen and (min-width: 1200px) {
    .my-4 {
      margin-top: 36px !important;
      margin-bottom: 36px !important; } }

.my-5 {
  margin-top: 36px !important;
  /* base * 3 */
  margin-bottom: 36px !important;
  /* base * 3 */ }
  @media screen and (min-width: 1200px) {
    .my-5 {
      margin-top: 72px !important;
      margin-bottom: 72px !important; } }

/* padding */
.p-0 {
  padding: 0px !important;
  /* 0px */ }

.p-1 {
  padding: 3px !important;
  /* base * .25 */ }
  @media screen and (min-width: 1200px) {
    .p-1 {
      padding: 6px !important; } }

.p-2 {
  padding: 6px !important;
  /* base * .5 */ }
  @media screen and (min-width: 1200px) {
    .p-2 {
      padding: 12px !important; } }

.p-3 {
  padding: 12px !important;
  /* base */ }
  @media screen and (min-width: 1200px) {
    .p-3 {
      padding: 24px !important; } }

.p-4 {
  padding: 18px !important;
  /* base * 1.5 */ }
  @media screen and (min-width: 1200px) {
    .p-4 {
      padding: 36px !important; } }

.p-5 {
  padding: 36px !important;
  /* base * 3 */ }
  @media screen and (min-width: 1200px) {
    .p-5 {
      padding: 72px !important; } }

/* padding top */
.pt-0 {
  padding-top: 0px !important;
  /* 0px */ }

.pt-1 {
  padding-top: 3px !important;
  /* base * .25 */ }
  @media screen and (min-width: 1200px) {
    .pt-1 {
      padding-top: 6px !important; } }

.pt-2 {
  padding-top: 6px !important;
  /* base * .5 */ }
  @media screen and (min-width: 1200px) {
    .pt-2 {
      padding-top: 12px !important; } }

.pt-3 {
  padding-top: 12px !important;
  /* base */ }
  @media screen and (min-width: 1200px) {
    .pt-3 {
      padding-top: 24px !important; } }

.pt-4 {
  padding-top: 18px !important;
  /* base * 1.5 */ }
  @media screen and (min-width: 1200px) {
    .pt-4 {
      padding-top: 36px !important; } }

.pt-5 {
  padding-top: 36px !important;
  /* base * 3 */ }
  @media screen and (min-width: 1200px) {
    .pt-5 {
      padding-top: 72px !important; } }

/* padding right */
.pr-0 {
  padding-right: 0px !important;
  /* 0px */ }

.pr-1 {
  padding-right: 3px !important;
  /* base * .25 */ }
  @media screen and (min-width: 1200px) {
    .pr-1 {
      padding-right: 6px !important; } }

.pr-2 {
  padding-right: 6px !important;
  /* base * .5 */ }
  @media screen and (min-width: 1200px) {
    .pr-2 {
      padding-right: 12px !important; } }

.pr-3 {
  padding-right: 12px !important;
  /* base */ }
  @media screen and (min-width: 1200px) {
    .pr-3 {
      padding-right: 24px !important; } }

.pr-4 {
  padding-right: 18px !important;
  /* base * 1.5 */ }
  @media screen and (min-width: 1200px) {
    .pr-4 {
      padding-right: 36px !important; } }

.pr-5 {
  padding-right: 36px !important;
  /* base * 3 */ }
  @media screen and (min-width: 1200px) {
    .pr-5 {
      padding-right: 72px !important; } }

/* padding bottom */
.pb-0 {
  padding-bottom: 0px !important;
  /* 0px */ }

.pb-1 {
  padding-bottom: 3px !important;
  /* base * .25 */ }
  @media screen and (min-width: 1200px) {
    .pb-1 {
      padding-bottom: 6px !important; } }

.pb-2 {
  padding-bottom: 6px !important;
  /* base * .5 */ }
  @media screen and (min-width: 1200px) {
    .pb-2 {
      padding-bottom: 12px !important; } }

.pb-3 {
  padding-bottom: 12px !important;
  /* base */ }
  @media screen and (min-width: 1200px) {
    .pb-3 {
      padding-bottom: 24px !important; } }

.pb-4 {
  padding-bottom: 18px !important;
  /* base * 1.5 */ }
  @media screen and (min-width: 1200px) {
    .pb-4 {
      padding-bottom: 36px !important; } }

.pb-5 {
  padding-bottom: 36px !important;
  /* base * 3 */ }
  @media screen and (min-width: 1200px) {
    .pb-5 {
      padding-bottom: 72px !important; } }

/* padding left */
.pl-0 {
  padding-left: 0px !important;
  /* 0px */ }

.pl-1 {
  padding-left: 3px !important;
  /* base * .25 */ }
  @media screen and (min-width: 1200px) {
    .pl-1 {
      padding-left: 6px !important; } }

.pl-2 {
  padding-left: 6px !important;
  /* base * .5 */ }
  @media screen and (min-width: 1200px) {
    .pl-2 {
      padding-left: 12px !important; } }

.pl-3 {
  padding-left: 12px !important;
  /* base */ }
  @media screen and (min-width: 1200px) {
    .pl-3 {
      padding-left: 24px !important; } }

.pl-4 {
  padding-left: 18px !important;
  /* base * 1.5 */ }
  @media screen and (min-width: 1200px) {
    .pl-4 {
      padding-left: 36px !important; } }

.pl-5 {
  padding-left: 36px !important;
  /* base * 3 */ }
  @media screen and (min-width: 1200px) {
    .pl-5 {
      padding-left: 72px !important; } }

/* padding left right */
.px-0 {
  padding-left: 0px !important;
  /* 0px */
  padding-right: 0px !important;
  /* 0px */ }

.px-1 {
  padding-left: 3px !important;
  /* base * .25 */
  padding-right: 3px !important;
  /* base * .25 */ }
  @media screen and (min-width: 1200px) {
    .px-1 {
      padding-left: 6px !important;
      padding-right: 6px !important; } }

.px-2 {
  padding-left: 6px !important;
  /* base * .5 */
  padding-right: 6px !important;
  /* base * .5 */ }
  @media screen and (min-width: 1200px) {
    .px-2 {
      padding-left: 12px !important;
      padding-right: 12px !important; } }

.px-3 {
  padding-left: 12px !important;
  /* base */
  padding-right: 12px !important;
  /* base */ }
  @media screen and (min-width: 1200px) {
    .px-3 {
      padding-left: 24px !important;
      padding-right: 24px !important; } }

.px-4 {
  padding-left: 18px !important;
  /* base * 1.5 */
  padding-right: 18px !important;
  /* base * 1.5 */ }
  @media screen and (min-width: 1200px) {
    .px-4 {
      padding-left: 36px !important;
      padding-right: 36px !important; } }

.px-5 {
  padding-left: 36px !important;
  /* base * 3 */
  padding-right: 36px !important;
  /* base * 3 */ }
  @media screen and (min-width: 1200px) {
    .px-5 {
      padding-left: 72px !important;
      padding-right: 72px !important; } }

/* padding top bottom */
.py-0 {
  padding-top: 0px !important;
  /* 0px */
  padding-bottom: 0px !important;
  /* 0px */ }

.py-1 {
  padding-top: 3px !important;
  /* base * .25 */
  padding-bottom: 3px !important;
  /* base * .25 */ }
  @media screen and (min-width: 1200px) {
    .py-1 {
      padding-top: 6px !important;
      padding-bottom: 6px !important; } }

.py-2 {
  padding-top: 6px !important;
  /* base * .5 */
  padding-bottom: 6px !important;
  /* base * .5 */ }
  @media screen and (min-width: 1200px) {
    .py-2 {
      padding-top: 12px !important;
      padding-bottom: 12px !important; } }

.py-3 {
  padding-top: 12px !important;
  /* base */
  padding-bottom: 12px !important;
  /* base */ }
  @media screen and (min-width: 1200px) {
    .py-3 {
      padding-top: 24px !important;
      padding-bottom: 24px !important; } }

.py-4 {
  padding-top: 18px !important;
  /* base * 1.5 */
  padding-bottom: 18px !important;
  /* base * 1.5 */ }
  @media screen and (min-width: 1200px) {
    .py-4 {
      padding-top: 36px !important;
      padding-bottom: 36px !important; } }

.py-5 {
  padding-top: 36px !important;
  /* base * 3 */
  padding-bottom: 36px !important;
  /* base * 3 */ }
  @media screen and (min-width: 1200px) {
    .py-5 {
      padding-top: 72px !important;
      padding-bottom: 72px !important; } }

.border-0 {
  border: 0px !important; }

.border-top-0 {
  border-top: 0px !important; }

.border-right-0 {
  border-right: 0px !important; }

.border-bottom-0 {
  border-bottom: 0px !important; }

.border-left-0 {
  border-left: 0px !important; }

/*Colors - BEGIN*/
/**** Colors - end *****/
:root {
  --echt-transition: cubic-bezier(0.25, 0.1, 0.25, 1); }

@-webkit-keyframes o2fa--move-right {
  0% {
    transform: translateX(-20px);
    opacity: 0; }
  26% {
    transform: translateX(0px);
    opacity: 1; }
  66% {
    transform: translateX(0px);
    opacity: 1; }
  100% {
    transform: translateX(20px);
    opacity: 0; } }

@keyframes o2fa--move-right {
  0% {
    transform: translateX(-20px);
    opacity: 0; }
  26% {
    transform: translateX(0px);
    opacity: 1; }
  66% {
    transform: translateX(0px);
    opacity: 1; }
  100% {
    transform: translateX(20px);
    opacity: 0; } }

@-webkit-keyframes o2fa--move-left {
  0% {
    transform: translateX(20px) rotate(180deg);
    opacity: 0; }
  26% {
    transform: translateX(0px) rotate(180deg);
    opacity: 1; }
  66% {
    transform: translateX(0px) rotate(180deg);
    opacity: 1; }
  100% {
    transform: translateX(-20px) rotate(180deg);
    opacity: 0; } }

@keyframes o2fa--move-left {
  0% {
    transform: translateX(20px) rotate(180deg);
    opacity: 0; }
  26% {
    transform: translateX(0px) rotate(180deg);
    opacity: 1; }
  66% {
    transform: translateX(0px) rotate(180deg);
    opacity: 1; }
  100% {
    transform: translateX(-20px) rotate(180deg);
    opacity: 0; } }

@-webkit-keyframes o2fa--move-down {
  0% {
    transform: translateY(-10px) rotate(90deg);
    opacity: 0; }
  26% {
    transform: translateY(0px) rotate(90deg);
    opacity: 1; }
  66% {
    transform: translateY(0px) rotate(90deg);
    opacity: 1; }
  100% {
    transform: translateY(10px) rotate(90deg);
    opacity: 0; } }

@keyframes o2fa--move-down {
  0% {
    transform: translateY(-10px) rotate(90deg);
    opacity: 0; }
  26% {
    transform: translateY(0px) rotate(90deg);
    opacity: 1; }
  66% {
    transform: translateY(0px) rotate(90deg);
    opacity: 1; }
  100% {
    transform: translateY(10px) rotate(90deg);
    opacity: 0; } }

@-webkit-keyframes grow-questionmark-error {
  0% {
    transform: scale(0);
    opacity: 0; }
  25% {
    transform: scale(0.5);
    opacity: 0.5; }
  50% {
    transform: scale(1);
    opacity: 0.8; }
  70% {
    transform: scale(1.3);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes grow-questionmark-error {
  0% {
    transform: scale(0);
    opacity: 0; }
  25% {
    transform: scale(0.5);
    opacity: 0.5; }
  50% {
    transform: scale(1);
    opacity: 0.8; }
  70% {
    transform: scale(1.3);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes grow-questionmark-success {
  0% {
    transform: scale(0);
    opacity: 0; }
  25% {
    transform: scale(0.5);
    opacity: 0.5; }
  50% {
    transform: scale(1);
    opacity: 0.8; }
  70% {
    transform: scale(1.3);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes grow-questionmark-success {
  0% {
    transform: scale(0);
    opacity: 0; }
  25% {
    transform: scale(0.5);
    opacity: 0.5; }
  50% {
    transform: scale(1);
    opacity: 0.8; }
  70% {
    transform: scale(1.3);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes slide-tooltip-top-bubble {
  0% {
    transform: translateY(-20px);
    opacity: 0; }
  33% {
    transform: translateY(-10px);
    opacity: 0.5; }
  66% {
    transform: translateY(-5px);
    opacity: 0.9; }
  100% {
    transform: translateY(0px);
    opacity: 1; } }

@keyframes slide-tooltip-top-bubble {
  0% {
    transform: translateY(-20px);
    opacity: 0; }
  33% {
    transform: translateY(-10px);
    opacity: 0.5; }
  66% {
    transform: translateY(-5px);
    opacity: 0.9; }
  100% {
    transform: translateY(0px);
    opacity: 1; } }

@-webkit-keyframes slide-out-tooltip-top-bubble {
  0% {
    transform: translateY(0px);
    opacity: 1;
    visibility: visible; }
  33% {
    transform: translateY(-5px);
    opacity: 0.9;
    visibility: visible; }
  66% {
    transform: translateY(-10px);
    opacity: 0.5;
    visibility: visible; }
  100% {
    transform: translateY(-20px);
    opacity: 0;
    visibility: hidden; } }

@keyframes slide-out-tooltip-top-bubble {
  0% {
    transform: translateY(0px);
    opacity: 1;
    visibility: visible; }
  33% {
    transform: translateY(-5px);
    opacity: 0.9;
    visibility: visible; }
  66% {
    transform: translateY(-10px);
    opacity: 0.5;
    visibility: visible; }
  100% {
    transform: translateY(-20px);
    opacity: 0;
    visibility: hidden; } }

@-webkit-keyframes slide-tooltip-bottom-bubble {
  0% {
    transform: translateY(20px);
    opacity: 0; }
  33% {
    transform: translateY(10px);
    opacity: 0.5; }
  66% {
    transform: translateY(5px);
    opacity: 0.9; }
  100% {
    transform: translateY(0px);
    opacity: 1; } }

@keyframes slide-tooltip-bottom-bubble {
  0% {
    transform: translateY(20px);
    opacity: 0; }
  33% {
    transform: translateY(10px);
    opacity: 0.5; }
  66% {
    transform: translateY(5px);
    opacity: 0.9; }
  100% {
    transform: translateY(0px);
    opacity: 1; } }

@-webkit-keyframes slide-out-tooltip-bottom-bubble {
  0% {
    transform: translateY(0px);
    opacity: 1;
    visibility: visible; }
  33% {
    transform: translateY(5px);
    opacity: 0.9;
    visibility: visible; }
  66% {
    transform: translateY(10px);
    opacity: 0.5;
    visibility: visible; }
  100% {
    transform: translateY(20px);
    opacity: 0;
    visibility: hidden; } }

@keyframes slide-out-tooltip-bottom-bubble {
  0% {
    transform: translateY(0px);
    opacity: 1;
    visibility: visible; }
  33% {
    transform: translateY(5px);
    opacity: 0.9;
    visibility: visible; }
  66% {
    transform: translateY(10px);
    opacity: 0.5;
    visibility: visible; }
  100% {
    transform: translateY(20px);
    opacity: 0;
    visibility: hidden; } }

@-webkit-keyframes slide-input-required-label {
  0% {
    transform: translateX(20px);
    opacity: 0; }
  33% {
    transform: translateX(10px);
    opacity: 0.5; }
  66% {
    transform: translatex(5px);
    opacity: 0.9; }
  100% {
    transform: translateX(0px);
    opacity: 1; } }

@keyframes slide-input-required-label {
  0% {
    transform: translateX(20px);
    opacity: 0; }
  33% {
    transform: translateX(10px);
    opacity: 0.5; }
  66% {
    transform: translatex(5px);
    opacity: 0.9; }
  100% {
    transform: translateX(0px);
    opacity: 1; } }

@-webkit-keyframes checkbox-do-uncheck-tick {
  0% {
    transform: scale(0);
    opacity: 0; }
  25% {
    transform: scale(0.25);
    opacity: 1; }
  50% {
    transform: scale(0.5);
    opacity: 1; }
  75% {
    transform: scale(0.75);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes checkbox-do-uncheck-tick {
  0% {
    transform: scale(0);
    opacity: 0; }
  25% {
    transform: scale(0.25);
    opacity: 1; }
  50% {
    transform: scale(0.5);
    opacity: 1; }
  75% {
    transform: scale(0.75);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes checkbox-do-uncheck-bg {
  0% {
    background-color: #0093d5; }
  25% {
    background-color: #0093d5; }
  50% {
    background-color: #0093d5; }
  75% {
    background-color: #0093d5; }
  100% {
    background-color: white; } }

@keyframes checkbox-do-uncheck-bg {
  0% {
    background-color: #0093d5; }
  25% {
    background-color: #0093d5; }
  50% {
    background-color: #0093d5; }
  75% {
    background-color: #0093d5; }
  100% {
    background-color: white; } }

@-webkit-keyframes checkbox-do-check {
  0% {
    transform: scale(1);
    opacity: 1; }
  25% {
    transform: scale(0.75);
    opacity: 1; }
  50% {
    transform: scale(0.5);
    opacity: 1; }
  75% {
    transform: scale(0.25);
    opacity: 1; }
  100% {
    transform: scale(0);
    opacity: 0; } }

@keyframes checkbox-do-check {
  0% {
    transform: scale(1);
    opacity: 1; }
  25% {
    transform: scale(0.75);
    opacity: 1; }
  50% {
    transform: scale(0.5);
    opacity: 1; }
  75% {
    transform: scale(0.25);
    opacity: 1; }
  100% {
    transform: scale(0);
    opacity: 0; } }

@-webkit-keyframes radio-do-check {
  0% {
    transform: scale(0);
    opacity: 0; }
  25% {
    transform: scale(0.25);
    opacity: 1; }
  50% {
    transform: scale(0.5);
    opacity: 1; }
  75% {
    transform: scale(0.75);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes radio-do-check {
  0% {
    transform: scale(0);
    opacity: 0; }
  25% {
    transform: scale(0.25);
    opacity: 1; }
  50% {
    transform: scale(0.5);
    opacity: 1; }
  75% {
    transform: scale(0.75);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes radio-do-uncheck {
  0% {
    transform: scale(1);
    opacity: 1; }
  25% {
    transform: scale(0.75);
    opacity: 1; }
  50% {
    transform: scale(0.5);
    opacity: 1; }
  75% {
    transform: scale(0.25);
    opacity: 1; }
  100% {
    transform: scale(0);
    opacity: 0; } }

@keyframes radio-do-uncheck {
  0% {
    transform: scale(1);
    opacity: 1; }
  25% {
    transform: scale(0.75);
    opacity: 1; }
  50% {
    transform: scale(0.5);
    opacity: 1; }
  75% {
    transform: scale(0.25);
    opacity: 1; }
  100% {
    transform: scale(0);
    opacity: 0; } }

@-webkit-keyframes range-slide-tooltip-bottom {
  0% {
    transform: translate(-50%, -280%);
    opacity: 0; }
  33% {
    transform: translate(-50%, -250%);
    opacity: 0.50; }
  66% {
    transform: translate(-50%, -220%);
    opacity: 1; }
  100% {
    transform: translate(-50%, -190%);
    opacity: 1; } }

@keyframes range-slide-tooltip-bottom {
  0% {
    transform: translate(-50%, -280%);
    opacity: 0; }
  33% {
    transform: translate(-50%, -250%);
    opacity: 0.50; }
  66% {
    transform: translate(-50%, -220%);
    opacity: 1; }
  100% {
    transform: translate(-50%, -190%);
    opacity: 1; } }

@-webkit-keyframes range-slide-tooltip-top {
  0% {
    transform: translate(-50%, -190%);
    opacity: 1; }
  33% {
    transform: translate(-50%, -220%);
    opacity: 1; }
  66% {
    transform: translate(-50%, -250%);
    opacity: 0.5; }
  100% {
    transform: translate(-50%, -280%);
    opacity: 0; } }

@keyframes range-slide-tooltip-top {
  0% {
    transform: translate(-50%, -190%);
    opacity: 1; }
  33% {
    transform: translate(-50%, -220%);
    opacity: 1; }
  66% {
    transform: translate(-50%, -250%);
    opacity: 0.5; }
  100% {
    transform: translate(-50%, -280%);
    opacity: 0; } }

/* Fonts */
/* Variables */
/* updated icons */
/*Colors*/
@font-face {
  font-family: 'o2font';
  font-display: block;
  src: url("../fonts/o2font.woff2") format("woff2"), url("../fonts/o2font.woff") format("woff"), url("../fonts/o2font.ttf") format("truetype"), url("../fonts/o2font.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="o2f-"], [class*=" o2f-"], [class^="o2f-"] *, [class*=" o2f-"] * {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'o2font' !important;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #032b5a;
  display: inline-flex;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@-webkit-keyframes o2f-spin {
  0% {
    -webkit-transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(359deg); } }

@keyframes o2f-spin {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(359deg); } }

.o2f-spin {
  display: inline-block;
  -webkit-animation: o2f-spin 2s infinite linear;
  animation: o2f-spin 2s infinite linear; }

.o2f-mobile:before {
  content: ""; }

.o2f-person:before {
  content: ""; }

.o2f-credit-card:before {
  content: ""; }

.o2f-home:before {
  content: ""; }

.o2f-lock:before {
  content: ""; }

.o2f-users:before {
  content: ""; }

.o2f-envelope-exclamation-2 .path1:before {
  content: "";
  color: #99cc00; }

.o2f-envelope-exclamation-2 .path2:before {
  content: "";
  margin-left: -1em; }

.o2f-call-list:before {
  content: ""; }

.o2f-mobile-close-2 .path1:before, .o2f-mobile-close-5 .path1:before {
  content: "";
  color: #ff3333; }

.o2f-mobile-close-2 .path2:before, .o2f-mobile-close-5 .path2:before {
  content: "";
  margin-left: -1em; }

.o2f-mobile-tick-2 .path1:before, .o2f-mobile-tick-5 .path1:before {
  content: "";
  color: #99cc00; }

.o2f-mobile-tick-2 .path2:before, .o2f-mobile-tick-5 .path2:before {
  content: "";
  margin-left: -1em; }

.o2f-coins:before {
  content: ""; }

.o2f-click:before {
  content: ""; }

.o2f-list:before {
  content: ""; }

.o2f-cancel:before, .o2f-cancel-2:before {
  content: ""; }

.o2f-download:before, .o2f-download-2:before, .o2f-download-3:before {
  content: ""; }

.o2f-file-code:before {
  content: ""; }

.o2f-sms-list:before {
  content: ""; }

.o2f-data-list:before {
  content: ""; }

.o2f-mms-list:before {
  content: ""; }

.o2f-vas-list:before {
  content: ""; }

.o2f-filter-save-disabled:before, .o2f-filter-save-disabled-2:before {
  content: ""; }

.o2f-filter-save:before, .o2f-filter-save-2:before {
  content: ""; }

.o2f-filter-manage:before, .o2f-filter-manage-2:before, .o2f-filter-manage-3 .path1:before {
  content: ""; }

.o2f-columns-manage:before, .o2f-columns-manage-2 .path1:before, .o2f-columns-manage-4 .path1:before {
  content: ""; }

.o2f-document-unpaid-due-date-2 .path1:before, .o2f-document-unpaid-due-date-8 .path1:before {
  content: "";
  color: #ff3333; }

.o2f-document-unpaid-due-date-2 .path2:before, .o2f-document-unpaid-due-date-8 .path2:before {
  content: "";
  margin-left: -1em; }

.o2f-document-unpaid-2 .path1:before, .o2f-document-unpaid-8 .path1:before {
  content: "";
  color: #99cc00; }

.o2f-document-unpaid-2 .path2:before, .o2f-document-unpaid-8 .path2:before {
  content: "";
  margin-left: -1em; }

.o2f-document-paid-2 .path1:before, .o2f-document-paid-8 .path1:before {
  content: "";
  color: #99cc00; }

.o2f-document-paid-2 .path2:before, .o2f-document-paid-8 .path2:before {
  content: "";
  margin-left: -1em; }

.o2f-document-unsigned-digital-2 .path1:before {
  content: "";
  color: #ff3333; }

.o2f-document-unsigned-digital-2 .path2:before {
  content: "";
  margin-left: -1em; }

.o2f-document-signed-digital-2 .path1:before {
  content: "";
  color: #99cc00; }

.o2f-document-signed-digital-2 .path2:before {
  content: "";
  margin-left: -1em; }

.o2f-document-unsigned:before, .o2f-document-unsigned-2:before {
  content: ""; }

.o2f-document-signed:before, .o2f-document-signed-2:before {
  content: ""; }

.o2f-undo:before {
  content: ""; }

.o2f-ported-msisdn-2 .path1:before, .o2f-ported-msisdn-4 .path1:before {
  content: "";
  color: #99cc00; }

.o2f-ported-msisdn-2 .path2:before, .o2f-ported-msisdn-4 .path2:before {
  content: "";
  margin-left: -1em; }

.o2f-credit-recharge-2 .path1:before, .o2f-credit-recharge-5 .path1:before {
  content: "";
  color: #99cc00; }

.o2f-credit-recharge-2 .path2:before, .o2f-credit-recharge-5 .path2:before {
  content: "";
  margin-left: -1em; }

.o2f-eye-closed:before {
  content: ""; }

.o2f-eye-opened:before {
  content: ""; }

.o2f-pay-bill:before {
  content: ""; }

.o2f-number-transfer:before, .o2f-number-transfer-2 .path1:before, .o2f-number-transfer-4 .path1:before {
  content: ""; }

.o2f-refresh-clock:before {
  content: ""; }

.o2f-search-tree:before {
  content: ""; }

.o2f-sporopay-2 .path1:before, .o2f-sporopay-8 .path1:before {
  content: "";
  color: #0b4c7d; }

.o2f-sporopay-2 .path2:before, .o2f-sporopay-8 .path2:before {
  content: "";
  margin-left: -5.6181640625em;
  color: #addff8; }

.o2f-success-statement:before, .o2f-success-statement-2:before {
  content: ""; }

.o2f-invoice:before {
  content: ""; }

.o2f-delivery-method:before {
  content: ""; }

.o2f-data-validation:before {
  content: ""; }

.o2f-contact-info:before {
  content: ""; }

.o2f-basic-info:before {
  content: ""; }

.o2f-home-house:before {
  content: ""; }

.o2f-hamburger:before {
  content: ""; }

.o2f-settings:before {
  content: ""; }

.o2f-selfcare-login:before, .o2f-selfcare-login-2:before {
  content: ""; }

.o2f-pay-the-bill:before {
  content: ""; }

.o2f-lightbulb:before {
  content: ""; }

.o2f-mastercard-6 .path1:before, .o2f-mastercard-22 .path1:before {
  content: "";
  color: #ec2127; }

.o2f-mastercard-6 .path2:before, .o2f-mastercard-22 .path2:before {
  content: "";
  margin-left: -1.6708984375em;
  color: #faa61b; }

.o2f-mastercard-6 .path3:before, .o2f-mastercard-22 .path3:before {
  content: "";
  margin-left: -1.6708984375em;
  color: black; }

.o2f-mastercard-6 .path4:before, .o2f-mastercard-22 .path4:before {
  content: "";
  margin-left: -1.6708984375em;
  color: white; }

.o2f-mastercard-6 .path5:before, .o2f-mastercard-22 .path5:before {
  content: "";
  margin-left: -1.6708984375em;
  color: black; }

.o2f-mastercard-6 .path:before, .o2f-mastercard-22 .path6:before {
  content: "";
  margin-left: -1.6708984375em;
  color: white; }

.o2f-visa-4 .path1:before {
  content: "";
  color: white; }

.o2f-visa-4 .path2:before {
  content: "";
  margin-left: -1.5556640625em;
  color: #f5b619; }

.o2f-visa-4 .path3:before {
  content: "";
  margin-left: -1.5556640625em;
  color: #2a2a6d; }

.o2f-visa-4 .path4:before {
  content: "";
  margin-left: -1.5556640625em;
  color: #2a2a6d; }

.o2f-4g-lte:before {
  content: ""; }

.o2f-vub-eplatby-7 .path1:before, .o2f-vub-eplatby-12 .path1:before {
  content: "";
  color: #d3ae2e; }

.o2f-vub-eplatby-7 .path2:before, .o2f-vub-eplatby-12 .path2:before {
  content: "";
  margin-left: -4.1162109375em;
  color: #19673b; }

.o2f-vub-eplatby-7 .path3:before, .o2f-vub-eplatby-12 .path3:before {
  content: "";
  margin-left: -4.1162109375em;
  color: #1d4579; }

.o2f-vub-eplatby-7 .path4:before, .o2f-vub-eplatby-12 .path4:before {
  content: "";
  margin-left: -4.1162109375em;
  color: #e36c29; }

.o2f-vub-eplatby-7 .path5:before, .o2f-vub-eplatby-12 .path5:before {
  content: "";
  margin-left: -4.1162109375em;
  color: #e36c29; }

.o2f-vub-eplatby-7 .path6:before, .o2f-vub-eplatby-12 .path6:before {
  content: "";
  margin-left: -4.1162109375em;
  color: #19673b; }

.o2f-vub-eplatby-7 .path7:before, .o2f-vub-eplatby-12 .path7:before {
  content: "";
  margin-left: -4.1162109375em;
  color: #979797; }

.o2f-tatrapay:before {
  content: ""; }

.o2f-leaf:before {
  content: ""; }

.o2f-bill-euro:before {
  content: ""; }

.o2f-my-calls:before {
  content: ""; }

.o2f-register:before {
  content: ""; }

.o2f-consumption:before {
  content: ""; }

.o2f-mass-activation:before {
  content: ""; }

.o2f-at:before {
  content: ""; }

.o2f-leave-home:before {
  content: ""; }

.o2f-read-more:before {
  content: ""; }

.o2f-invoice-list:before {
  content: ""; }

.o2f-packages-services:before {
  content: ""; }

.o2f-invoice-settings:before, .o2f-icons-11:before {
  content: ""; }

.o2f-community-test:before {
  content: ""; }

.o2f-unbilled-usage:before {
  content: ""; }

.o2f-internet-packages:before {
  content: ""; }

.o2f-invoice-data:before {
  content: ""; }

.o2f-recharge-internet:before {
  content: ""; }

.o2f-service-settings:before {
  content: ""; }

.o2f-recharge-other:before {
  content: ""; }

.o2f-view-bill:before {
  content: ""; }

.o2f-adress-book:before {
  content: ""; }

.o2f-number-overview:before {
  content: ""; }

.o2f-recent-calls:before {
  content: ""; }

.o2f-call-history:before {
  content: ""; }

.o2f-smart-id:before {
  content: ""; }

.o2f-recharge-history:before {
  content: ""; }

.o2f-sim-activation:before {
  content: ""; }

.o2f-other-account-management:before {
  content: ""; }

.o2f-sms-gate:before {
  content: ""; }

.o2f-subfolder:before {
  content: ""; }

.o2f-sms-settings:before {
  content: ""; }

.o2f-sms-sent:before {
  content: ""; }

.o2f-migration:before {
  content: ""; }

.o2f-personal-data:before {
  content: ""; }

.o2f-protect-update:before {
  content: ""; }

.o2f-login-data:before {
  content: ""; }

.o2f-password-change:before {
  content: ""; }

.o2f-help:before {
  content: ""; }

.o2f-innovation:before {
  content: ""; }

.o2f-health-surveys:before {
  content: ""; }

.o2f-detail-list:before {
  content: ""; }

.o2f-discount-coupons:before {
  content: ""; }

.o2f-two-persons:before {
  content: ""; }

.o2f-shopping-persons:before {
  content: ""; }

.o2f-transfer:before {
  content: ""; }

.o2f-order-status:before {
  content: ""; }

.o2f-person-document:before {
  content: ""; }

.o2f-shopping-cart:before {
  content: ""; }

.o2f-gear:before {
  content: ""; }

.o2f-minus:before {
  content: ""; }

.o2f-plus:before {
  content: ""; }

.o2f-trash:before {
  content: ""; }

.o2f-times:before {
  content: ""; }

.o2f-minus-circle:before {
  content: ""; }

.o2f-plus-circle:before {
  content: ""; }

.o2f-times-circle:before {
  content: ""; }

.o2f-edit:before {
  content: ""; }

.o2f-earth:before {
  content: ""; }

.o2f-recovery-enabled:before {
  content: ""; }

.o2f-recovery-disabled:before {
  content: ""; }

.o2f-chevron-right:before {
  content: ""; }

.o2f-chevron-top:before {
  content: ""; }

.o2f-chevron-left:before {
  content: ""; }

.o2f-chevron-bottom:before {
  content: ""; }

.o2f-chevron-right-circle:before {
  content: ""; }

.o2f-chevron-top-circle:before {
  content: ""; }

.o2f-chevron-left-circle:before {
  content: ""; }

.o2f-chevron-bottom-circle:before {
  content: ""; }

.o2f-tooltip-info-o:before {
  content: ""; }

.o2f-tooltip-info:before {
  content: ""; }

.o2f-tooltip-info-circle:before {
  content: ""; }

.o2f-tooltip-alert-o:before {
  content: ""; }

.o2f-tooltip-alert:before {
  content: ""; }

.o2f-tooltip-alert-circle:before {
  content: ""; }

.o2f-tooltip-success-o:before {
  content: ""; }

.o2f-tooltip-success:before {
  content: ""; }

.o2f-tooltip-success-circle:before, .o2f-check:before {
  content: ""; }

.o2f-attachment:before {
  content: ""; }

.o2f-items:before {
  content: ""; }

.o2f-o:before {
  content: ""; }

.o2f-circle:before {
  content: ""; }

.o2f-pdf-document-download-2:before, .o2f-pdf-document-download-6:before, .o2f-document-pdf-2:before, .o2f-document-pdf:before {
  content: ""; }

.o2f-file-excel-2 .path1:before, .o2f-document-xls-2:before, .o2f-document-xls:before {
  content: ""; }

.o2f-document-csv-2:before, .o2f-document-csv:before {
  content: ""; }

.o2f-document-doc-2:before, .o2f-document-doc:before {
  content: ""; }

.o2f-document-xml-2:before, .o2f-document-xml:before {
  content: ""; }

.o2f-document-xlsx-2:before, .o2f-document-xlsx:before {
  content: ""; }

.o2f-document-docx-2:before, .o2f-document-docx:before {
  content: ""; }

.o2f-loader:before {
  content: ""; }

.o2f-search:before {
  content: ""; }

.o2f-calendar:before {
  content: ""; }

.o2f-alert-warning-triangle:before {
  content: ""; }

.o2f-alert-info:before {
  content: ""; }

.o2f-alert-info-circle:before {
  content: ""; }

.o2f-alert-info-o:before {
  content: ""; }

.o2f-list-bullet:before {
  content: ""; }

.o2f-payment-paper-bill:before {
  content: ""; }

.o2f-payment-pick-up:before {
  content: ""; }

.o2f-payment-courier:before {
  content: ""; }

.o2f-payment-store:before {
  content: ""; }

.o2f-star:before {
  content: ""; }

.o2f-clock:before {
  content: ""; }

.o2f-calendar:before {
  content: ""; }

.o2f-facebook:before {
  content: ""; }

.o2f-twitter:before {
  content: ""; }

.o2f-youtube:before {
  content: ""; }

.o2f-google-plus:before {
  content: ""; }

.o2f-instagram:before {
  content: ""; }

.o2f-buyout-yes:before {
  content: ""; }

.o2f-buyout-no:before {
  content: ""; }

.o2f-4g-sim:before {
  content: ""; }

.o2f-facebook-square:before {
  content: ""; }

.o2f-twitter-square:before {
  content: ""; }

.o2f-youtube-square:before {
  content: ""; }

.o2f-instagram-square:before {
  content: ""; }

/* new icons 20.8.2019 */
.o2f-answer:before {
  content: ""; }

.o2f-business:before {
  content: ""; }

.o2f-call:before {
  content: ""; }

.o2f-chat:before {
  content: ""; }

.o2f-coins:before {
  content: ""; }

.o2f-arrow-right:before {
  content: ""; }

.o2f-coverage:before {
  content: ""; }

.o2f-credit:before {
  content: ""; }

.o2f-dashboard:before {
  content: ""; }

.o2f-device:before {
  content: ""; }

.o2f-e-invoice:before {
  content: ""; }

.o2f-global:before {
  content: ""; }

.o2f-guide:before {
  content: ""; }

.o2f-help-2:before {
  content: ""; }

.o2f-internet-speed:before {
  content: ""; }

.o2f-menu:before {
  content: ""; }

.o2f-other-apps:before {
  content: ""; }

.o2f-other-services:before {
  content: ""; }

.o2f-packages:before {
  content: ""; }

.o2f-reading:before {
  content: ""; }

.o2f-roaming:before {
  content: ""; }

.o2f-settings_1:before {
  content: ""; }

.o2f-settings_2:before {
  content: ""; }

.o2f-smart-devices:before {
  content: ""; }

.o2f-speed:before {
  content: ""; }

.o2f-star_1:before {
  content: ""; }

.o2f-times-o:before {
  content: ""; }

/* @import url(../../../../../node_modules/font-awesome/css/font-awesome.min.css); */
.cc-window {
  padding: 20px !important;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  height: auto;
  bottom: 12% !important;
  border-radius: 0 2px 2px 0;
  overflow: visible;
  z-index: 100000; }
  .cc-window.cc-floating {
    max-width: 100%;
    margin-right: 12px; }
    @media screen and (min-width: 768px) {
      .cc-window.cc-floating {
        max-width: 360px;
        margin-right: 0; } }
  .cc-window.cc-left {
    left: 0%; }
  @media screen and (min-width: 768px) {
    .cc-window {
      bottom: 20%; } }
  .cc-window .cc-btn, .cc-window .btn {
    border: 0;
    font-size: 14px;
    padding: 7px 12px;
    color: white; }
    .cc-window .cc-btn:hover, .cc-window .cc-btn:focus, .cc-window .btn:hover, .cc-window .btn:focus {
      text-decoration: none; }
    .cc-window .cc-btn.cookie-close, .cc-window .btn.cookie-close {
      width: 24px;
      min-width: 24px;
      height: 24px;
      flex: 0;
      padding: 3px 0px;
      border-radius: 100%;
      font-size: 12px;
      position: absolute;
      top: -12px;
      right: -12px;
      margin: 0; }
  .cc-window .btn-white {
    background-color: #aaaaaa;
    border: 0; }
    .cc-window .btn-white:hover, .cc-window .btn-white :focus {
      background-color: #b9b9b9;
      border: 0;
      color: white; }
    @media screen and (min-width: 768px) {
      .cc-window .btn-white {
        max-width: 150px !important; } }
  .cc-window .cc-link {
    opacity: 1;
    display: inline; }
    .cc-window .cc-link:hover {
      opacity: 0.9; }

.cc-revoke {
  display: none !important; }

body.s-cookie-on {
  padding: 0 !important; }

body.s-cookie-on .cc-window {
  position: fixed !important;
  top: auto !important; }

/* O2 Components */
/*Colors - BEGIN*/
/**** Colors - end *****/
:root {
  --echt-transition: cubic-bezier(0.25, 0.1, 0.25, 1); }

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  background-color: white;
  color: #3b4653;
  line-height: 1.71; }
  body:not(.dialog-iframe-root-node):before {
    background-image: linear-gradient(to bottom, #060636, #054d89 48%, #1A9DD9 73%, #7dbde7 81%, #fff);
    height: 700px;
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1; }
  body.animation-off * {
    animation-duration: 0s !important;
    -webkit-animation-duration: 0s !important;
    transition: background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important; }

#main {
  background-color: white; }
  @media screen and (min-width: 768px) {
    #main {
      min-height: 330px; } }

/******* Typography *******/
b,
strong {
  font-weight: 600; }

small,
.small {
  font-size: 12px; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #343434;
  margin-bottom: 12px; }

h2,
h3,
h4,
h5,
h6,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0px; }

h1 {
  margin-top: 18px;
  letter-spacing: -1px;
  font-weight: 300; }

h2 {
  letter-spacing: -0.87px;
  font-weight: 300; }

h3 {
  letter-spacing: -0.44px;
  font-weight: 300; }

h5,
h6 {
  font-weight: 600; }

h6 {
  font-size: 15px; }

h2.has-icon,
h3.has-icon {
  line-height: 32px; }

h4.has-icon,
h5.has-icon,
h6.has-icon {
  line-height: 24px; }

h3.pouzitie {
  margin-top: 32px; }

.has-icon i {
  margin-right: 10px; }

p {
  line-height: 1.71;
  margin-bottom: 12px;
  font-size: 14px;
  color: #3b4653; }

p.bulb-note {
  padding: 14px 12px 13px 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  background: #d5f2ff;
  border-radius: 2px; }
  p.bulb-note:before {
    font-family: o2font;
    content: "\e953";
    margin: 0 0 0 -39px;
    font-size: 30px;
    position: absolute;
    top: 3px;
    color: #000066;
    line-height: 50px; }

ul.ul,
ol.ol {
  margin-top: 24px;
  padding-left: 0; }

ul {
  list-style: none outside none;
  padding: 0;
  margin: 0; }
  ul.ul li {
    list-style: none;
    padding-left: 30px;
    color: #3b4653;
    padding-bottom: 12px; }
    ul.ul li:before {
      font-family: 'o2font' !important;
      content: '\e9be';
      margin: 0 5px 0 -20px;
      color: #005177;
      position: relative;
      left: -12px; }
  ul ul {
    margin-top: 12px; }
    ul ul li {
      list-style: none; }

ol {
  list-style: decimal outside none;
  margin-left: 15px; }
  ol.ol li {
    color: #005177;
    padding-bottom: 12px;
    padding-left: 12px;
    font-weight: 600; }
    ol.ol li span {
      color: #3b4653;
      font-weight: 400; }

a {
  color: #0093d5;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s ease-in-out; }
  a:hover {
    color: #0070a2;
    cursor: pointer;
    text-decoration: underline; }
  a:active {
    color: #0093d5;
    text-decoration: underline; }

a[class^='o2f-'],
a[class*=' o2f-'],
a.back,
a.more,
a.more-down {
  color: #0093d5;
  display: inline-block;
  font-weight: 600; }
  a[class^='o2f-']:before, a[class^='o2f-']:after,
  a[class*=' o2f-']:before,
  a[class*=' o2f-']:after,
  a.back:before,
  a.back:after,
  a.more:before,
  a.more:after,
  a.more-down:before,
  a.more-down:after {
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
            animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    -webkit-animation-timing-function: var(--echt-transition);
            animation-timing-function: var(--echt-transition);
    -webkit-animation-iteration-count: 0;
            animation-iteration-count: 0;
    transition: all .2s ease-in-out;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-family: 'o2font';
    color: #0093d5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    text-decoration: none; }
  a[class^='o2f-']:hover,
  a[class*=' o2f-']:hover,
  a.back:hover,
  a.more:hover,
  a.more-down:hover {
    color: #0070a2;
    cursor: pointer;
    text-decoration: none; }
    a[class^='o2f-']:hover:before, a[class^='o2f-']:hover:after,
    a[class*=' o2f-']:hover:before,
    a[class*=' o2f-']:hover:after,
    a.back:hover:before,
    a.back:hover:after,
    a.more:hover:before,
    a.more:hover:after,
    a.more-down:hover:before,
    a.more-down:hover:after {
      color: #0070a2;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite; }
  a[class^='o2f-']:active,
  a[class*=' o2f-']:active,
  a.back:active,
  a.more:active,
  a.more-down:active {
    color: #0093d5;
    text-decoration: none; }
    a[class^='o2f-']:active:before,
    a[class*=' o2f-']:active:before,
    a.back:active:before,
    a.more:active:before,
    a.more-down:active:before {
      color: #0093d5; }

a[class^='o2f-'],
a[class*=' o2f-'] {
  font-family: inherit !important;
  line-height: inherit; }

a[class^='o2f-']:before,
a[class*=' o2f-']:before {
  font-size: 18px;
  position: relative;
  top: 2px;
  margin-right: 10px; }

a [class*='icon-'] {
  text-decoration: none; }

a.more::after {
  position: relative;
  top: 1px;
  content: '\e936';
  -webkit-animation-name: o2fa--move-right;
          animation-name: o2fa--move-right;
  margin-left: 10px; }

a.back::before {
  margin-right: 10px;
  content: '\e936';
  transform: rotate(180deg);
  -webkit-animation-name: o2fa--move-left;
          animation-name: o2fa--move-left;
  top: -2px;
  position: relative; }

a.more-down::after {
  content: '\e936';
  transform: rotate(90deg);
  -webkit-animation-name: o2fa--move-down;
          animation-name: o2fa--move-down;
  margin-left: 10px; }

.document-icon-16 {
  width: 16px;
  line-height: 16px;
  margin: 3px; }

a.document-icon:hover {
  text-decoration: none; }

label {
  font-weight: normal; }

img {
  max-width: 100%; }

#menu-article .more {
  bottom: 18px;
  display: inline;
  font-size: 13px;
  text-align: center; }

#menu-article {
  padding-top: 12px;
  padding-left: 1px; }

#menu-article .box {
  padding-top: 0px; }

.border-radius-right-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.overflow-y-visible {
  overflow-y: visible !important; }

/* GRID - example used in GL*/
.gridshow > div[class*='row'] > [class^='col-'] {
  padding-bottom: 12px/2; }

.gridshow > div[class*='row'] > [class^='col-'] .col-inner {
  padding: 12px;
  text-align: center; }

.col-inner.color {
  background: #d1edf7;
  border: 1px solid rgba(86, 61, 124, 0.2); }

.grid-example {
  font-weight: 600;
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid #cacaca;
  padding: 10px;
  text-align: center; }

div.error {
  border-width: 0 !important; }

.sims {
  display: block;
  margin: 22px auto 22px; }

.color-box {
  margin-top: 50px;
  font-size: 16px; }
  .color-box tbody td {
    padding: 8px 20px !important;
    vertical-align: middle !important; }
    .color-box tbody td div {
      width: 40px;
      height: 40px;
      margin: 0 auto; }

.remove-link-with-icon {
  background: transparent url("../images/eshop/close-sm.png") no-repeat;
  display: inline-block;
  background-position: right;
  padding-right: 23px; }

/* vertical centering */
.vertical-centering-container {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.row.is-flex {
  display: flex;
  flex-wrap: wrap; }

.row.is-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto; }

.row.is-flex::before,
.row.is-flex::after {
  content: normal; }

.row.is-centered {
  justify-content: center;
  align-items: center; }

.popup.popup-form {
  top: 25%;
  width: 720px; }

.overlay {
  opacity: 0.5; }

.overlay-layer {
  background: rgba(205, 222, 238, 0.8);
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.inline-block {
  display: inline-block; }

/*Colors - BEGIN*/
/**** Colors - end *****/
:root {
  --echt-transition: cubic-bezier(0.25, 0.1, 0.25, 1); }

.document-icon-16 {
  width: 16px;
  line-height: 16px;
  /*font-size: 16px;*/
  /*padding-left: 16px;*/
  margin: 3px; }

.document-icon-32 {
  width: 32px;
  line-height: 32px;
  margin: 3px 6px 3px 3px; }

.document-icon {
  background: transparent none no-repeat center center;
  display: inline-block; }

.changedText, .changedText2 {
  display: inline-block; }

.changedTextError {
  display: none; }

.changedTextDashbord {
  display: none;
  width: calc(100% - 41px); }

a.document-icon:hover {
  text-decoration: none; }

.document-icon span {
  display: none; }

.icon-pdf-16 {
  background-image: url("../images/eportal/icons/pdf-16.gif"); }

.icon-signedPdf-16 {
  background-image: url("../images/eportal/icons/signedPdf-16.gif"); }

.icon-xls-16 {
  background-image: url("../images/eportal/icons/xls-16.gif"); }

.icon-unknown-16 {
  background-image: url("../images/eportal/icons/unknownFile-16.gif"); }

.icon-pdf-32 {
  background-image: url("../images/eportal/icons/pdf-32.png"); }

.icon-signedPdf-32 {
  background-image: url("../images/eportal/icons/signedPdf-32.gif"); }

.icon-xls-32 {
  background-image: url("../images/eportal/icons/xls-32.png"); }

.icon-csv-32 {
  background-image: url("../images/eportal/icons/csv-32px2.png"); }

.icon-zip-32 {
  background-image: url("../images/eportal/icons/zip-32.png"); }

.icon-unknown-32 {
  background-image: url("../images/eportal/icons/unknownFile-32.gif"); }

/*Colors - BEGIN*/
/**** Colors - end *****/
:root {
  --echt-transition: cubic-bezier(0.25, 0.1, 0.25, 1); }

.container, .container-fluid {
  padding-left: 20px;
  padding-right: 20px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .container, .container-fluid {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 992px) {
    .container, .container-fluid {
      padding-left: 15px;
      padding-right: 15px; } }

#main > .container:first-child,
#main > .container-fluid:first-child {
  padding-top: 12px; }

/*Colors - BEGIN*/
/**** Colors - end *****/
:root {
  --echt-transition: cubic-bezier(0.25, 0.1, 0.25, 1); }

.blocks {
  border-top: 1px solid #dcdcdc;
  position: relative; }

.blocks > div {
  border-bottom: 1px solid #dcdcdc;
  padding: 7px 10px 15px; }

.blocks > div p {
  margin: 0;
  padding-left: 23px; }

.blocks h6 {
  float: left;
  margin-bottom: 1px; }

.blocks h6 i {
  margin-right: 10px; }

.blocks .clearfix div {
  float: right;
  margin-top: 6px; }

.blocks .clearfix div a {
  color: #5c5c5c; }

.blocks .overlay-layer .clearfix div a {
  color: #0093d5;
  vertical-align: top; }

.blocks .overlay-layer .clearfix div .btn {
  margin-right: 15px; }

input[type="reset"].btn-white:active .dropdown-menu {
  top: 42px;
  padding: 0;
  border-radius: 2px;
  border-color: #cacaca;
  background-color: #f9f9f9;
  box-shadow: none; }

.directory > a:hover {
  text-decoration: none; }

.directory > a > div {
  min-height: 190px;
  max-height: 190px; }

.serial-wrapper {
  position: relative;
  margin: 6px 0 12px; }

div.DTCR_pointer {
  width: 1px;
  background-color: #0259C4;
  z-index: 201; }

.extra-data-link-disabled {
  pointer-events: visible !important;
  padding: 4px 6px !important; }

.slow-extra-data-mobile form {
  margin: 0px auto; }

.slow-extra-data-mobile input {
  margin-top: 5px;
  font-size: 14px;
  padding: 4px 6px; }

.ui-widget {
  font-family: 'Open Sans', sans-serif; }

.readonly-panel .readonly-row {
  border-bottom: 1px solid #e5e4e3;
  padding: 5px 0; }

.readonly-panel .readonly-row label {
  width: 50%;
  margin: 0px; }

.readonly-panel .readonly-row span {
  width: 49%;
  vertical-align: middle; }

.readonly-row .question-mark {
  top: 0px;
  margin-bottom: 0px; }

.readonly-row .data-value > span {
  float: left; }

.readonly-row .data-value {
  color: #3b4653;
  vertical-align: middle;
  margin-bottom: 6px; }

.popup-confirm {
  text-align: center; }

.popup {
  max-width: 90%; }

.dropdown-menu li.NoListItem {
  padding: 8px; }

#topmenu #navigation {
  margin-bottom: 0px; }

.button-or-text-container .button-or-text {
  margin: 0 6px; }

.button-or-text-container input + .button-or-text {
  margin-left: 3px; }

.popup .button-or-text-container .btn {
  margin-right: 4px; }

.bootstrap-select .dropdown-menu li span.text {
  min-height: 1em;
  display: inline-block; }

.collapse-grid {
  width: auto;
  margin-right: 12px; }

/* STACKBAR */
.stackbar-unit-label {
  text-align: center;
  position: relative;
  top: 5px; }

.stackbar-value {
  display: block; }

.stackbar-unit {
  float: left;
  border-right: 1px solid white; }

.stackbar-unit-bar {
  height: 25px;
  background: #9ecafb; }

.stackbar:after {
  content: '';
  display: block;
  clear: both; }

.stackbar .stackbar-unit:first-child .stackbar-unit-bar {
  -webkit-border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px; }

.stackbar .stackbar-unit:last-child .stackbar-unit-bar {
  -webkit-border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.stackbar-unit-1 .stackbar-unit-bar {
  background: #68a7f8; }

.stackbar-unit-2 .stackbar-unit-bar {
  background: #8fbefc; }

.stackbar-unit-3 .stackbar-unit-bar {
  background: #bedafe; }

.stackbar-unit-grey .stackbar-unit-bar {
  background: #cbcbcb; }

.stackbar-unit-grey {
  color: #cbcbcb; }

.stackbar-label-arrow {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 7px solid #cbcbcb;
  margin: auto; }

.stackbar-unit-1 .stackbar-label-arrow {
  border-bottom-color: #68a7f8; }

.stackbar-unit-2 .stackbar-label-arrow {
  border-bottom-color: #8fbefc; }

.stackbar-unit-3 .stackbar-label-arrow {
  border-bottom-color: #bedafe; }

.stackbar-unit-grey .stackbar-label-arrow {
  border-bottom-color: #cbcbcb; }

.payments-summary {
  display: table; }

.payments-summary span {
  text-align: right; }

.payments-summary > span {
  display: table-row; }

.payments-summary > span > span {
  display: table-cell; }

.payments-summary > span > span:nth-child(2) {
  padding-left: 24px; }

.flag {
  float: left;
  position: relative;
  top: 1px;
  margin-right: 8px;
  width: 25px;
  height: 16px;
  background: url(../images/flags.png) no-repeat; }

.flag.sk {
  background-position: 0 -512px; }

.flag.cz {
  background-position: 0 -80px; }

/* Syntax highlighter */
div.syntaxhighlighter .container:before {
  content: none; }

div.syntaxhighlighter .container:after {
  content: none; }

.form--trash .fileitem.uploading .file-column {
  margin-top: 6px; }

.form--trash .fileitem.uploaded .file-column {
  margin-top: 12px; }

.form--trash .fileitem.uploading .file-upload-progress {
  padding-left: 58px; }

.form--trash .fileitem .file:hover .btn-left.link-file.btn-white,
.form--trash .fileitem .file:hover .btn.btn-trash {
  background-color: #fefde8; }

.form--trash .fileitem .btn-left.link-file.btn-white::before {
  background: none; }

.form--trash .file .btn.btn-trash {
  display: none;
  position: absolute;
  right: 0;
  top: 0; }

.form--trash .file:hover .btn.btn-trash {
  display: block;
  min-width: 0;
  width: 42px;
  height: 42px;
  padding: 12px;
  background: white;
  top: 1px;
  right: 1px; }

.small-delete-icon {
  background: transparent url(../images/eshop/close-sm.png) center center no-repeat;
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  width: 16px;
  cursor: pointer; }

.help-tip {
  background: transparent url(../images/eshop/bulb.png) left center no-repeat;
  padding-left: 30px; }

.delivery .button-wrapper {
  margin-bottom: 15px; }

/********** Tabular data boxes **********/
.tabular-box-data .data-header,
.tabular-box-data .data-value {
  margin-bottom: 8px;
  width: 50%; }

.tabular-box-data .data-header,
.tabular-box-data .courier-note-hint {
  clear: left; }

.additional-info ul.ul {
  padding-left: 0; }

/********** Tile box **********/
.tile-box {
  padding: 6px 30px 6px 7px;
  height: 95px; }

.tile-box .question-mark {
  position: absolute;
  top: 2px;
  right: 10px; }

.tile-box .blue {
  position: absolute;
  bottom: 15px; }

.tile-box.tile-box-link {
  padding: 6px 7px;
  display: table;
  width: 100%; }

.tile-box.tile-box-link a {
  display: table-cell;
  text-align: center;
  vertical-align: middle; }

.product-colors > span {
  float: left;
  margin-top: 2px;
  min-height: 40px; }

.share > a {
  background: transparent url(../images/share.png) left center no-repeat;
  color: #343434;
  display: block;
  font-weight: 600;
  line-height: 32px;
  padding-left: 40px; }

.number-badge {
  display: inline-block;
  min-width: 15px;
  padding: 0 4px;
  height: 15px;
  position: absolute;
  border-radius: 6px;
  background: #01b7b4;
  font-size: 10px;
  position: absolute;
  right: -30px;
  top: -8px;
  color: white; }

.number-badge.empty {
  display: none !important; }

.number-badge:hover,
.number-badge:focus {
  text-decoration: none; }

.coupon-details span:last-child {
  margin-top: -1em;
  display: block; }

.stick-bottom .custom-content .btn {
  padding: 4px 0px; }

.execption-stacktrace {
  max-height: 16em;
  overflow: scroll; }

.portlet-msg-info {
  background-color: #EEF6FC; }

.portlet-msg-error {
  background-color: #fdd; }

.portlet-msg-alert {
  background-color: #ffc; }

.portlet-msg-success {
  background-color: #F4FDEF; }

.portlet-msg-warning {
  background-color: #EEF6FC; }

.eportal-portlet .password .pstrength-bar,
.eportal-portlet .password .pstrength-info,
.eportal-portlet .password input.password {
  float: left; }

.eportal-portlet .password .pstrength-bar {
  margin: 7px 5px 0 5px; }

.address_result {
  color: white;
  text-align: center; }

.hintCloud {
  background-color: #d2e9ff;
  border: 1px solid #9bcdff;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  color: black;
  text-align: left;
  border-radius: 10px;
  width: 350px;
  z-index: 999; }

.hintCloud .hintImage {
  background: transparent url("../images/question-mark.png") 4px 1px no-repeat;
  display: block;
  border: 0 none;
  padding: 3px 6px 3px 27px;
  _zoom: 1; }

.firefox .hintCloud .hintImage {
  background-position: 4px 1px; }

.portlet-boundary_openx_WAR_liferayportlets_ .portlet-msg-error,
#eportal-openx .portlet-msg-info, #eportal-openx .portlet-msg-error {
  display: none; }

#selectorPanel {
  padding-left: 0px;
  padding-right: 0px; }

#selectorPanel input[type=text] {
  color: gray; }

#selectorPanel .searchPanel .search-input {
  width: 100% !important; }

#selectorPanel .searchPanel .btn-search .glyphicon {
  right: 0px;
  top: 1px; }

#selectorPanel .searchPanel {
  padding: 8px;
  border-bottom: 1px solid #cacaca;
  overflow: hidden; }

#selectorPanel .form-line input {
  width: 120px; }

@media screen and (max-width: 993px) {
  #hidablePanel {
    position: relative; } }

@media screen and (min-width: 994px) {
  #hidablePanel {
    position: absolute; } }

#hidablePanel {
  display: none;
  min-width: 10em;
  overflow: hidden;
  z-index: 10;
  margin: 0px;
  top: -1px;
  width: 100% !important; }

#hidablePanel li {
  background: none;
  /*padding: 0 10px;*/
  overflow: hidden;
  line-height: 30px;
  font-size: 13px; }

#selectorPanel .link,
#selectorPanel .btn-link {
  display: none; }

#selectorPanel input[type=text] {
  color: gray; }

#hidablePanel .msisdnSelector {
  margin: 0; }

#selectorPanel ~ label.control-label {
  text-align: left;
  margin-left: 6px; }

#selectorPanel .searchPanel .bootstrap-select-searchbox {
  padding: 0;
  border: none; }

#hidablePanel .msisdnSelector li > a,
#hidablePanel .msisdnSelector li > a :hover {
  text-decoration: none;
  min-height: 1em;
  display: block;
  line-height: 1.42857143; }

#comboPanel {
  position: relative; }

#comboPanel select {
  margin: 0 10px;
  padding-left: 5px;
  width: auto;
  max-width: 260px;
  min-width: 140px; }

#comboPanel option {
  display: none;
  width: 1px;
  height: 1px;
  z-index: -1; }

#hidablePanel .searchPanel .ui-autocomplete-loading + .input-group-btn .btn-search {
  display: none; }

#loadingIndicator {
  display: none;
  background-color: white;
  background: white url(../images/eportal/guidelines/ajax-loader.gif) no-repeat 50% 40%;
  position: absolute;
  z-index: 60;
  height: 100%;
  width: 100%;
  opacity: 0.9;
  border-radius: 5px; }

#loadingIndicator p {
  position: relative;
  top: 40%;
  text-align: center;
  margin-top: 25px; }

#hider {
  position: absolute;
  top: auto;
  top: initial;
  width: 100%;
  left: 0;
  height: 44px;
  z-index: 40;
  background: transparent;
  cursor: pointer; }

#buttonPanel.ng {
  border-top: 1px solid #cacaca;
  height: 40px;
  padding: 4px; }

form[name=actionDetailCallList] .selectIcon {
  margin-top: 2px; }

form[name=actionGroups] .selectIcon, form[name=actionDepartments] .selectIcon {
  margin-top: 3px; }

.textBoxFilter input[name="msisdn"] {
  background-image: url(../images/eportal/input-msisdn.gif);
  background-repeat: no-repeat;
  background-position: right;
  width: 160px; }

#main .small-shopping-cart-body {
  display: none; }

.portlet-msg-info, .portlet-msg-error, .portlet-msg-alert, .portlet-msg-success, .portlet-msg-warning {
  text-align: center;
  padding: 24px 10px;
  border-radius: 6px;
  border: 1px solid transparent; }

.portlet-msg-error {
  background: #fff1f1;
  border-color: #e90808;
  color: #e90808; }

.portlet-msg-alert {
  background: #fff9e9;
  border-color: #fdd1a2;
  color: #f88912; }

.portlet-msg-success {
  background: #ecffe9;
  border-color: #22b733;
  color: #22b733; }

.portlet-msg-warning {
  background: #e9f9ff;
  border-color: #2d9ed8;
  color: #2d9ed8; }

.header-promotion .tip-sticker {
  background: #d90f0f;
  border-radius: 50%;
  color: white;
  font-weight: 600;
  width: 20px;
  display: inline-block;
  padding-right: 2px;
  margin-left: 5px; }

.header-promotion span {
  color: white !important; }

.header-promotion a:hover {
  text-decoration: none; }

.header-promotion a:hover span:first-child {
  text-decoration: underline; }

.header-promotion {
  display: none; }

.header-promotion.loaded {
  display: block; }

.grid-margin {
  margin-left: 12px; }

/*Colors - BEGIN*/
/**** Colors - end *****/
:root {
  --echt-transition: cubic-bezier(0.25, 0.1, 0.25, 1); }

.captcha img {
  padding-bottom: 10px; }

.captcha .regenerate {
  font-size: 9px;
  text-transform: lowercase; }

.honey-input {
  display: none !important; }

.honey-input-off {
  position: absolute !important;
  left: -10000px !important; }

.captcha-hidden {
  display: none !important; }

/*Colors - BEGIN*/
/**** Colors - end *****/
:root {
  --echt-transition: cubic-bezier(0.25, 0.1, 0.25, 1); }

.semibold {
  font-weight: 600; }

.text-dots {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  color: white; }

.ellipsis:focus {
  color: #3b4653; }

.subtitle {
  font-size: 16px;
  margin-bottom: 1em; }

.center {
  text-align: center; }

.left {
  float: left; }

.right {
  float: right; }

.clear {
  line-height: 0;
  clear: both; }

.block {
  display: block; }

.h-center {
  text-align: center; }

.h-right {
  text-align: right; }

.aligment-top {
  vertical-align: top; }

.position-relative {
  position: relative; }

.truncate {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/*Colors - BEGIN*/
/**** Colors - end *****/
:root {
  --echt-transition: cubic-bezier(0.25, 0.1, 0.25, 1); }

/********** HW detail **********/
.product-detail-header,
.product-colors,
.product-rating,
.product-image-wrapper,
.product-photos-wrapper,
#fivestar,
.box-corner-round .select-package,
.product-bonus {
  margin-bottom: 20px; }

.product-detail-header h1 {
  margin: 0; }

.icon-link {
  width: 130px; }

.icon-link-icon {
  display: table-cell;
  height: 50px;
  width: 50px; }

.icon-link-icon2 {
  background: transparent url(../images/eshop/porovnat_50.png) right top no-repeat; }

.icon-link-text {
  color: #0086c3;
  display: table-cell;
  height: 50px;
  line-height: 15px;
  padding-left: 5px;
  text-align: left;
  text-decoration: none;
  vertical-align: middle; }

.product-colors .product-colors-header {
  display: inline-block;
  margin-right: 16px; }

.product-colors .product-color,
.product-colors .product-color .color {
  border-radius: 50%;
  box-sizing: content-box;
  cursor: pointer;
  vertical-align: middle; }

.product-colors .product-color {
  display: inline-block;
  height: 25px;
  width: 25px;
  padding: 0px; }

.product-colors .product-color .color {
  background-color: #01b7b4;
  display: block;
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 29px;
  color: #f7f7f7;
  font-size: 16px;
  background: none; }

.product-colors .product-color.selected {
  border: 2px solid #dadada; }

.product-colors .product-color .color.border {
  border: 1px solid grey;
  height: 23px;
  width: 23px;
  color: #bebebe; }

.product-colors .product-color .color.border .glyphicon {
  position: relative;
  top: 0px; }

.product-image-wrapper {
  text-align: center; }

.product-image-wrapper .product-image {
  max-height: 370px;
  max-width: 300px; }

.product-photos-wrapper .product-photo-thumbnail {
  border: 1px solid #cacaca;
  border-radius: 2px;
  cursor: pointer;
  margin: 0 1% 2%;
  padding: 3px;
  text-align: center; }

.product-photos-wrapper .product-photo-thumbnail:hover {
  border-color: #505050; }

.like-button-wrapper {
  margin-bottom: 15px; }

.box-corner-round {
  background: white;
  border: 1px solid #bebebe;
  border-radius: 2px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  margin-bottom: 12px;
  position: relative; }

.box-corner-round-inner {
  padding: 12px; }

.box-corner-round.no-shadow {
  box-shadow: none; }

.box-corner-round.box-gray {
  background: #f9f9f9;
  padding: 12px; }

.box-corner-round.active {
  border: 2px solid #419eda; }

.box-corner-round > h2,
.box-corner-round > h3,
.box-corner-round h4,
.box-corner-round-inner > h2,
.box-corner-round-inner > h3,
.box-corner-round-inner h4 {
  margin: 0 0 15px; }

.select-package .bootstrap-select .filter-option,
.products-sorting .bootstrap-select .filter-option,
.select-number .bootstrap-select .filter-option,
.new-number-select .bootstrap-select .filter-option,
.form-horizontal .bootstrap-select .filter-option {
  max-width: 100%;
  padding-right: 0;
  position: static; }

.icon-info {
  background: transparent url(../images/eshop/icon-info-16.png) 0 0 no-repeat;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  margin-bottom: 5px;
  margin-left: 8px;
  position: relative;
  top: 6px;
  width: 18px; }

.box-corner-round-left {
  border-right: 1px solid #dadada;
  padding-right: 15px; }

.box-corner-round-right {
  border-left: 1px solid #dadada;
  margin-left: -1px;
  padding-left: 15px; }

form.new-package-product {
  margin-bottom: 0; }

.box-corner-round .select-package {
  margin-top: 20px; }

.current-price-wrapper {
  margin-bottom: 10px; }

.current-price-wrapper span.current-price {
  font-size: 125%;
  padding-left: 50px; }

.product-bonus span {
  padding-left: 20px; }

.package-details-list {
  margin-left: 12px; }

.package-details-list li {
  list-style-image: url(../images/eshop/list_item_arrow.png); }

.box-corner-round .button-wrapper {
  margin-top: 20px; }

a.arrow-link {
  background: transparent url(../images/eshop/arrow-right.png) left center no-repeat;
  padding-left: 9px; }

.product-specification-table .table tr th {
  text-align: left; }

.product-specification-table .table tr th,
.product-specification-table .table tr td {
  padding: 6px 8px !important; }

.product-specification-table td div {
  line-height: 28px; }

.products-list {
  margin: 20px 0; }

.products-list .product-wrapper {
  text-align: center; }

.products-list .product-wrapper > div {
  margin-bottom: 10px; }

.products-list .product-wrapper .product-header {
  height: 40px;
  margin-bottom: 0;
  margin-top: 8px; }

.products-list .product-wrapper .product-price {
  font-size: 115%; }

/********** HW listing **********/
.tab-content.wide {
  padding: 1px 0; }

.tab-content.wide > div {
  padding: 2px 0; }

form.products-listing-filters {
  border-bottom: 1px solid #dadada;
  margin: 20px 0 15px;
  padding-bottom: 25px; }

.products-listing .select-package {
  margin-right: 42px;
  width: 350px; }

.products-listing-filter {
  width: 556px; }

.products-listing-filters select.multiselect + div.btn-group {
  padding: 2px 0 0;
  white-space: nowrap; }

.products-listing-filters select.multiselect + div.btn-group button.multiselect {
  padding-left: 10px;
  text-align: left; }

.products-listing-filter select.multiselect + div.btn-group,
.products-listing-filter select.multiselect + div.btn-group button.multiselect {
  min-width: 0 !important;
  width: 140px !important; }

.products-listing-filter-level1 {
  margin-bottom: 10px; }

.clear-filter {
  display: inline-block;
  height: 16px;
  margin-right: 10px;
  width: 16px; }

.product-search {
  padding-top: 2px;
  width: 215px; }

.products-listing-filter .input-group-btn .btn-search {
  padding: 0 0 0 7px; }

.available-switch {
  margin-bottom: 5px; }

.available-switch input {
  margin-right: 5px;
  vertical-align: top; }

.product-specifications-filter-trigger {
  padding-right: 14px; }

.product-specifications-filter-trigger.collapsed {
  background: transparent url(../images/eshop/arrow-down.png) right center no-repeat; }

.product-specifications-filter-trigger.expanded {
  background: transparent url(../images/eshop/arrow-up.png) right center no-repeat; }

#product-specifications-filter {
  clear: both; }

.range-wrapper.col-sm-offset-4 {
  margin-left: 33.3333%; }

form.products-sorting {
  margin: 12px 0 0; }

form.compare-switch {
  margin: 10px 0 0; }

.products-listing .products-list .product-wrapper {
  margin-bottom: 40px; }

.products-list .product-image > a {
  display: block;
  max-height: 140px;
  min-height: 140px; }

.products-listing .products-list .product-price {
  margin-bottom: 2px; }

.products-listing .products-list .product-special {
  font-size: 13px;
  margin-bottom: 5px;
  position: absolute;
  width: 94.5%; }

.products-listing .products-list .button-wrapper {
  height: 31px;
  margin-top: 25px; }

.products-listing .products-list .button-wrapper.out-of-stock > div {
  line-height: 18px;
  margin-top: -4px; }

.products-list .product-wrapper .compare {
  bottom: -12px;
  left: 36%;
  position: absolute; }

.products-listing .pagination {
  display: inline-block; }

.products-listing .show-all {
  display: inline-block;
  margin-left: 30px; }

.products-list .product-wrapper .tip {
  background: #00b9b5;
  border-radius: 100%;
  color: white;
  font-weight: 600;
  height: 76px;
  left: 54px;
  line-height: 18px;
  padding-top: 18px;
  position: absolute;
  top: 15px;
  width: 76px;
  transform: rotate(-30deg); }

.checkmark {
  background: transparent url(../images/eshop/checkmark.png) center center no-repeat;
  width: 13px;
  height: 13px;
  display: inline-block; }

/**** Eshop cart ****/
/* new icon*/
